<!-- @format -->

<template>
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col md="5" sm="4" xl="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <label>每页条数</label>
          <v-select v-model="pageSize" :options="pageSizeOptions" :clearable="false" class="per-page-selector d-inline-block ml-50 mr-1" />
        </b-col>

        <!-- Search -->
        <b-col>
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input v-model="userId" class="d-inline-block mr-1" placeholder="请输入会员ID" />
            <BFormDatepicker v-model="startTime" class="d-inline-block mr-1" placeholder="请选择开始时间" :reset-button="true" />
            <BFormDatepicker v-model="endTime" class="d-inline-block mr-1" placeholder="请选择结束时间" :reset-button="true" />
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      bordered
      tbody-tr-class="text-center"
      :selectable="selectable"
      select-mode="multi"
      @row-clicked="contentChange"
      ref="orderListTable"
      :items="fetchInvoices"
      responsive
      :fields="tableColumns"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      class="position-relative text-center"
      style="min-height: 400px"
    />

    <div class="mx-2 mb-2">
      <b-row>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination v-model="currentPage" :total-rows="totalInvoices" :per-page="pageSize" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
    <b-modal id="detailModal" title="详情" :hide-footer="true" size="lg">
      <table class="table">
        <thead>
          <tr>
            <th>保单号</th>
            <th>受保人</th>
            <th>已付TP</th>
            <th>已付佣金</th>
            <th>创建时间</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in selectedItem.xiangqing" :key="item.id">
            <td>{{ item.id }}</td>
            <td>{{ item.protect_user }}</td>
            <td>{{ item.change_tp }}</td>
            <td>{{ item.change_moeny }}</td>
            <td>{{ item.created_at }}</td>
          </tr>
        </tbody>
      </table>
    </b-modal>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BTable, BPagination, BFormDatepicker } from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { getCurrentInstance, ref } from '@vue/composition-api'
import 'flatpickr/dist/flatpickr.css'
import useInvoicesList from './useOrderList'
import orderModule from './OrderManagerList'

export default {
  components: {
    BFormDatepicker,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    vSelect,
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'user-order'
    const { proxy } = getCurrentInstance()
    const selectedItem = ref({}) // 新增
    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) {
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, orderModule)
    }

    const {
      startTime,
      endTime,
      userId,
      fetchInvoices,
      tableColumns,
      pageSize,
      currentPage,
      totalInvoices,
      dataMeta,
      pageSizeOptions,
      orderListTable,
      refetchData,
    } = useInvoicesList(proxy)

    const selectable = ref(true)

    const piliangArray = ref([])
    const contentChange = (item, index, event) => {
      if (piliangArray.value.indexOf(item.id) == -1) {
        piliangArray.value.push(item.id)
      } else {
        piliangArray.value.splice(piliangArray.value.indexOf(item.id), 1)
      }
      selectedItem.value = item // 新增
      proxy.$bvModal.show('detailModal') // 新增
    }

    return {
      startTime,
      endTime,
      userId,
      piliangArray,
      contentChange,
      selectedItem, // 新增
      selectable,
      fetchInvoices,
      tableColumns,
      pageSize,
      currentPage,
      totalInvoices,
      dataMeta,
      pageSizeOptions,
      orderListTable,
      refetchData,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 100px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
.wocc {
  >>> .dropdown-toggle {
    padding: 0px !important;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.form-control-time {
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  display: block;
  width: 100%;
  height: 2.714rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  color: #6e6b7b;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>
