/** @format */
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    orderDetail: {},
  },
  mutations: {
    setOrderDetail(state, data) {
      state.orderDetail = data
    },
  },
  actions: {
    fetchListData(ctx, param) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('/user/tp-money', { params: param })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
